import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as AccountIcon } from 'applicaiton/assets/account-box.svg';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/local-hospital.svg';
import { ReactComponent as ListIcon } from 'applicaiton/assets/list.svg';
import { ReactComponent as BellIcon } from '../assets/bell.svg';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';

export const useMenuList = (isClinicOwnerProfessional: boolean) => {
  const { t } = useTranslation();
  const userId = getUserId();
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isAdmin = userRoleExactMatch(UserRoles.admin);
  const isSecretary = userRole?.includes(UserRoles.secretary);
  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const { isPrivateDoctor } = useClinicOwner();

  const list = [
    {
      path: PathNames.schedule,
      name: t('clinicsConfiguration.aside_menu.schedule'),
      image: CalendarIcon,
    },
    {
      path: `${PathNames.profile}/${userId}`,
      name: t('clinicsConfiguration.aside_menu.my_profile'),
      image: AccountIcon,
    },
    {
      path: PathNames.notifications,
      name: t('clinicsConfiguration.aside_menu.notifications'),
      image: BellIcon,
    },
  ];

  if (isAdmin || isSecretary) {
    return [
      ...(doShowAgenda
        ? [
            {
              path: PathNames.schedule,
              name: t('clinicsConfiguration.aside_menu.schedule'),
              image: CalendarIcon,
            },
          ]
        : []),
      {
        path: `${PathNames.profile}/${userId}`,
        name: t('clinicsConfiguration.aside_menu.my_profile'),
        image: AccountIcon,
      },
    ];
  }

  if (isClinicOwner) {
    const professionalMenu = [
      {
        path: PathNames.clinicOwnerSchedule,
        name: t('clinicsConfiguration.aside_menu.my_agenda'),
        image: PlusIcon,
      },
    ];
    const clinicsAgendaMenu = {
      path: PathNames.schedule,
      name: t('clinicsConfiguration.aside_menu.clinics_agendas'),
      image: CalendarIcon,
    };

    return [
      ...((!isPrivateDoctor && [clinicsAgendaMenu]) || []),
      ...(isClinicOwnerProfessional ? professionalMenu : []),
      {
        path: PathNames.notifications,
        name: t('clinicsConfiguration.aside_menu.notifications'),
        image: BellIcon,
      },
      {
        path: PathNames.waitingList,
        name: t('clinicsConfiguration.aside_menu.waiting_list'),
        image: ListIcon,
      },
      {
        path: `${PathNames.profile}/${userId}`,
        name: t('clinicsConfiguration.aside_menu.my_profile'),
        image: AccountIcon,
      },
    ];
  }

  return list;
};
