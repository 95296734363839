import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import {
  Wrapper,
  StyledDivider,
  ButtonsWrapper,
  SubscriptionSection,
  EmptyState,
} from './styles';
import { FormProps } from './types';
import EmptySubscriptionSection from '../EmptySubscriptionSection';
import AddSubscriptionModal from '../AddSubscriptionModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import SubscriptionItem from '../SubscriptionItem';
import {
  createClinicOwnerProfessional,
  createProfessional,
  updateProfessional,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import {
  setCreatedProfessionalProfile,
  setProfileChanges,
  setProfileSubscriptions,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { PathNames } from 'applicaiton/routes';
import SuccessModal from '../SuccessModal';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import {
  ClinicProfessionalUpdateDto,
  CpSubscriptionCreationDto,
  ProfessionalCreationDto,
  ProfessionalUpdateWarningDto,
} from '@docbay/schemas';
import { CreateSubscription } from 'applicaiton/store/reducers/Professionals/models';
import { v4 as uuid } from 'uuid';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { EmptyStateIcon } from 'applicaiton/assets';
import {
  getProfilesFromSessionStorage,
  Profile,
} from 'applicaiton/sessionStorage/auth';
import { usersRefreshToken } from 'applicaiton/store/reducers/Users/ActionCreators';
import { ProfessionalUpdateWarningsModal } from 'common/components/ProfessionalUpdateWarningsModal';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';

const ProfessionalSubscriptions: FC<FormProps> = ({
  onBackBtnClick,
  isClinicOwnerProfile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isPrivateDoctor,
    updatePaymentAccountStatus,
    isPaymentAccountActive,
    isLoading,
  } = useClinicOwner();
  const [isOpenAddSubscription, setIsOpenAddSubscription] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [saveProfessionalWarnings, setSaveProfessionalWarnings] = useState<
    ProfessionalUpdateWarningDto[]
  >([]);

  const {
    isLoading: isLoadingProfessionals,
    profileChanges,
    existingProfessional,
    subscriptions,
    isCreatedProfessionalProfile,
  } = useAppSelector((state) => state.createProfessionalProfile);

  const {
    isLoading: isLoadingProfessionalsEdit,
    isUpdatedProfessionalProfile,
  } = useAppSelector((state) => state.professionalProfile);

  useEffect(() => {
    const arr: any = [];

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.subscriptionsToAdd?.length) {
        clinic.subscriptionsToAdd?.map((subscription) => {
          arr.push({
            id: uuid(),
            clinicId: clinic.clinicId,
            title: subscription.title,
            price: subscription.price!,
            durationInWeeks: subscription.durationInWeeks!,
            teleconsultationMinutes: subscription.teleconsultationMinutes!,
            onlineHours: subscription.onlineHours,
          });
        });
      }
    });

    dispatch(setProfileSubscriptions(arr));
  }, [profileChanges]);

  useEffect(() => {
    updatePaymentAccountStatus();
  }, []);

  const closePopup = () => {
    setShowSuccessModal(false);
    dispatch(setProfileChanges(null));
    navigate(
      isPrivateDoctor ? PathNames.clinicOwnerSchedule : PathNames.professionals,
    );
  };

  const closeCreationPopup = () => {
    dispatch(setProfileChanges(null));
    dispatch(professionalProfileActions.setUpdatedProfessionalProfile());
    dispatch(setCreatedProfessionalProfile());
    navigate(
      isPrivateDoctor ? PathNames.clinicOwnerSchedule : PathNames.professionals,
    );
  };

  const setSubscriptionIntoClinic = (data: CreateSubscription) => {
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.clinicId === data.clinicId) {
        const newData: CpSubscriptionCreationDto = {
          title: data.title!,
          price: data.price!,
          durationInWeeks: data.durationInWeeks!,
          teleconsultationMinutes: data.teleconsultationMinutes!,
          onlineHours: data.onlineHours,
        };

        newClinicsRelations.push({
          ...clinic,
          subscriptionsToAdd: [
            ...((clinic.subscriptionsToAdd as CpSubscriptionCreationDto[]) ||
              []),
            newData,
          ],
        });
      } else {
        newClinicsRelations.push(clinic);
      }
    });

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(setProfileChanges(prof!));
    setIsOpenAddSubscription(false);
  };

  const setClinicActiveForPrivateDoctor = (
    profileChanges: ProfessionalCreationDto,
  ) => {
    if (!isPrivateDoctor) {
      return profileChanges;
    }
    const newClinicsRelations: ClinicProfessionalUpdateDto[] =
      profileChanges?.clinicsRelations?.map((clinic) => ({
        ...clinic,
        active: true,
      })) || [];
    return {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };
  };

  const onSubmit = async () => {
    if (existingProfessional) {
      const response = await dispatch(
        updateProfessional({
          id: existingProfessional.id,
          professional: profileChanges!,
        }),
      );
      handleSaveProfessionalResponse(response);
    } else {
      if (isClinicOwnerProfile) {
        const profileToSave = setClinicActiveForPrivateDoctor(profileChanges!);
        const response = await dispatch(
          createClinicOwnerProfessional(profileToSave),
        );
        if (response.meta.requestStatus === 'fulfilled') {
          const profile: Profile[] | null = getProfilesFromSessionStorage();
          if (profile) {
            dispatch(
              usersRefreshToken({
                refreshToken: profile[0].refreshToken,
                email: profile[0].email,
              }),
            );
          }
          handleSaveProfessionalResponse(response);
        }
      } else {
        const response = await dispatch(createProfessional(profileChanges!));
        handleSaveProfessionalResponse(response);
      }
    }
  };
  const handleSaveProfessionalResponse = (response: any) => {
    if (response.meta.requestStatus !== 'fulfilled') {
      return;
    }
    dispatch(setProfileSubscriptions([]));
    if (response.payload?.warnings) {
      console.log('warnings:', response.payload?.warnings);
      setSaveProfessionalWarnings(response.payload?.warnings);
    } else {
      setShowSuccessModal(true);
    }
    dispatch(setProfileSubscriptions([]));
  };

  const handleBackBtn = () => {
    onBackBtnClick();
  };

  const handleOpenAddSubscription = () => {
    setIsOpenAddSubscription(true);
  };

  const handleCloseAddSubscription = () => {
    setIsOpenAddSubscription(false);
  };

  const handleDelete = (data: CreateSubscription) => {
    const indexOfObject = subscriptions!.findIndex(
      (object) => object.id === data.id,
    );

    const newSubscriptions = [...subscriptions];
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    newSubscriptions!.splice(indexOfObject, 1);

    const subs = newSubscriptions.filter(
      (item) => item.clinicId === data.clinicId,
    );

    const newSubs = subs.map((item) => {
      return {
        title: item.title!,
        price: Number(item.price)!,
        durationInWeeks: item.durationInWeeks!,
        teleconsultationMinutes: item.teleconsultationMinutes!,
        onlineHours: item.onlineHours,
      };
    });

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.clinicId === data.clinicId) {
        newClinicsRelations.push({
          ...clinic,
          subscriptionsToAdd: newSubs,
        });
      } else {
        newClinicsRelations.push(clinic);
      }
    });

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(setProfileChanges(prof!));
  };
  const handleCloseWarningsModal = () => {
    setSaveProfessionalWarnings([]);
    closePopup();
  };

  return (
    <Wrapper>
      {(isLoadingProfessionalsEdit || isLoadingProfessionals) && <Loader />}
      {isPaymentAccountActive && !subscriptions.length && (
        <EmptyState>
          <EmptyStateIcon />
          <p>{t('professional_profile.subscriptions.empty')}</p>
          <SecondaryButton onClick={handleOpenAddSubscription}>
            <AddIcon />
            {t('professional_profile.subscriptions.add_subscription')}
          </SecondaryButton>
        </EmptyState>
      )}
      {!subscriptions.length && !isPaymentAccountActive && (
        <EmptySubscriptionSection
          onRefreshPaymentAccountStatus={updatePaymentAccountStatus}
        />
      )}
      {!!subscriptions.length && (
        <SubscriptionSection>
          <p>{t('professional_profile.subscriptions.subscription_list')}</p>
          {subscriptions?.map((item, index) => {
            return (
              <SubscriptionItem
                key={index}
                data={item}
                onDelete={handleDelete}
              />
            );
          })}
          <SecondaryButton onClick={handleOpenAddSubscription}>
            <AddIcon />
            {t('professional_profile.subscriptions.add_subscription')}
          </SecondaryButton>
        </SubscriptionSection>
      )}

      <StyledDivider />
      <ButtonsWrapper>
        <SecondaryButton onClick={handleBackBtn}>{t('back')}</SecondaryButton>
        <PrimaryButton
          type={'button'}
          onClick={onSubmit}
          disabled={isLoadingProfessionals}
        >
          {isPrivateDoctor ? t('save') : t('professional_profile.send_invite')}
        </PrimaryButton>
      </ButtonsWrapper>
      {isOpenAddSubscription && (
        <AddSubscriptionModal
          key={'add'}
          isOpen={isOpenAddSubscription}
          onClose={handleCloseAddSubscription}
          onAdd={setSubscriptionIntoClinic}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        profileName={`${profileChanges?.firstName || ''} ${
          profileChanges?.lastName || ''
        }`}
        profileEmail={profileChanges?.email || ''}
        onClose={closePopup}
        isClinicOwnerProfile={isClinicOwnerProfile}
      />
      <SuccessModal
        open={isCreatedProfessionalProfile || isUpdatedProfessionalProfile}
        profileName={`${profileChanges?.firstName || ''} ${
          profileChanges?.lastName || ''
        }`}
        profileEmail={profileChanges?.email || ''}
        onClose={closeCreationPopup}
        isClinicOwnerProfile={isClinicOwnerProfile}
      />
      <ProfessionalUpdateWarningsModal
        isClinicOwnerProfile={isClinicOwnerProfile}
        isOpen={saveProfessionalWarnings.length > 0}
        warnings={saveProfessionalWarnings}
        onClose={handleCloseWarningsModal}
        onButtonClick={handleCloseWarningsModal}
      />
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default ProfessionalSubscriptions;
