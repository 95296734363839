export enum ViewBy {
  Patients = 'patientConfig',
  Doctor = 'professionalConfig',
  Admin = 'adminConfig',
  Secretary = 'secretaryConfig',
}

export enum SettingsTypes {
  OneDayReminder = '1-day Reminder',
  AppointmentCancellation = 'Appointment Cancellation',
  AppointmentRescheduled = 'Appointment Rescheduled',
  WaitingList = 'Waiting List',
  DocumentAddedToAppointment = 'Document Added to Appointment',
  NotificationOfMedicalResults = 'Notification of Medical results',
  BookingConfirmation = 'Booking Confirmation',
}
