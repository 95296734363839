import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionsWrapper, Wrapper } from './styles';
import { EmptySubscriptionProps } from './modules';
import { SecondaryButton } from 'common/components';
import { WarningCircleIcon, AddIcon } from 'applicaiton/assets';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';

const EmptySubscriptionSection: FC<EmptySubscriptionProps> = ({
  onRefreshPaymentAccountStatus,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <>
        <WarningCircleIcon />
        <p>{t('professional_profile.subscriptions.configure_billing')}</p>
        <ActionsWrapper>
          <Link to={PathNames.clinicBilling} target="_blank">
            <SecondaryButton>
              <AddIcon />
              {t('professional_profile.subscriptions.go_to_billing')}
            </SecondaryButton>
          </Link>
          <p onClick={onRefreshPaymentAccountStatus}>
            {t('professional_profile.subscriptions.refresh')}
          </p>
        </ActionsWrapper>
      </>
    </Wrapper>
  );
};
export default EmptySubscriptionSection;
