import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import {
  StyledModal,
  Title,
  StyledCloseIcon,
  SubscriptionInfo,
  Row,
  ButtonWrapper,
  FormsWrapper,
} from './styles';
import { Dropdown, Input, SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';
import { useSubscriptionsForm } from '../../hooks/useSubscriptionsForm';
import { Option } from 'common/components/Dropdown/models';
import { getWeekDurationOptions } from 'common/helpers/getWeekDuration';
import { getMonthDurationOptions } from 'common/helpers/getMonthDurationOptions';
import PrimaryButton from 'common/components/PrimaryButton';
import { v4 as uuid } from 'uuid';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { getIsUserPrivateDoctor } from 'applicaiton/sessionStorage/auth';
import { ModalProps } from './types';
import OnlineHours from 'common/components/OnlineHours';

const AddSubscriptionModal: FC<ModalProps> = ({
  onClose,
  onAdd,
  isOpen,
  data,
}) => {
  const { t } = useTranslation();
  const isPrivateDoctor = getIsUserPrivateDoctor();
  const [weekDuration, setWeekDuration] = useState<Option>();
  const [monthDuration, setMonthDuration] = useState<Option>();

  const { editedProfessional } = useAppSelector((state) => state.privateDoctor);

  const {
    setValue,
    errors,
    getValues,
    watch,
    handleSubmit,
    setError,
    register,
    clearErrors,
  } = useSubscriptionsForm({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    setWeekDuration(getWeekDurationOptions[1]);
    setMonthDuration(getMonthDurationOptions[0]);
  }, []);

  useEffect(() => {
    if (data) {
      setValue('clinicId', String(data!.clinicId));
      setValue('title', String(data!.title));
      setValue('price', String(data!.price));
      setValue('durationInWeeks', Number(data!.durationInWeeks));
      setValue('durationInMonth', Number(data.durationInWeeks!) / 4);
      setValue('teleconsultationMinutes', Number(data.teleconsultationMinutes));
      setValue('hours', data?.onlineHours || []);

      setWeekDuration(getWeekDurationOptions[Number(data!.durationInWeeks)]);
      setMonthDuration(
        getMonthDurationOptions[Number(data.durationInWeeks!) / 4],
      );
    }
  }, [data]);

  useEffect(() => {
    if (isPrivateDoctor && editedProfessional?.clinicsRelations?.length) {
      const clinic = editedProfessional?.clinicsRelations[0]!;

      setValue('clinicId', clinic?.clinic?.id!);
    }
  }, [isPrivateDoctor, editedProfessional]);

  const clinicsOptions = useMemo(() => {
    const options = editedProfessional?.clinicsRelations?.map((item) => {
      return {
        value: item!.clinic!.id!,
        label: item!.clinic!?.name as string,
      };
    });

    return options || [];
  }, [editedProfessional]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleSelectClinic = (clinicId: string) => {
    setValue('clinicId', clinicId, { shouldValidate: true });
  };

  const handleChangePrice = (price: string) => {
    const isIntNumber = price.indexOf('.') < 0;
    const isValidDecimalNumber = price.slice(price.indexOf('.')).length < 4;
    if (isIntNumber || isValidDecimalNumber) {
      setValue('price', price, {
        shouldValidate: !!Object.keys(errors).length,
      });
    } else {
      setError('price', { message: t('appointment_types.min_price') || '' });
    }
  };

  const onSubmit = async () => {
    const {
      clinicId,
      title,
      price,
      durationInWeeks,
      teleconsultationMinutes,
      durationInMonth,
      hours,
    } = getValues();

    const duration =
      Number(weekDuration?.value || 0) > 0
        ? Number(durationInWeeks)
        : Number(durationInMonth) * 4;

    onAdd({
      id: data ? data.id : uuid(),
      clinicId: clinicId!,
      title: title!,
      price: Number(price)!,
      durationInWeeks: duration!,
      teleconsultationMinutes: teleconsultationMinutes!,
      onlineHours: hours,
    });
  };

  const handleDurationInWeeksChanged = (value: Option) => {
    if (Number(value.value) > 0) {
      setMonthDuration(getMonthDurationOptions[0]);
      setWeekDuration(value);
      setValue('durationInWeeks', value.value as number);
      clearErrors();
    } else {
      if (Number(monthDuration?.value) === 0) {
        setError('durationInWeeks', {
          message: t('professional_profile.subscriptions.min_duration') || '',
        });
      }
    }
  };

  const handleDurationInMonthChanged = (value: Option) => {
    if (Number(value.value) > 0) {
      setMonthDuration(value);
      setWeekDuration(getWeekDurationOptions[0]);
      setValue('durationInMonth', value.value as number);
      clearErrors();
    } else {
      if (Number(weekDuration?.value) === 0) {
        setError('durationInMonth', {
          message: t('professional_profile.subscriptions.min_duration') || '',
        });
      }
    }
  };

  const hasChanges = useMemo(() => {
    const {
      clinicId,
      title,
      price,
      durationInWeeks,
      teleconsultationMinutes,
      durationInMonth,
      hours,
    } = getValues();

    const duration =
      Number(weekDuration?.value || 0) > 0
        ? Number(durationInWeeks)
        : Number(durationInMonth) * 4;

    const newData = {
      id: data ? data.id : uuid(),
      clinicId: clinicId!,
      title: title!,
      price: Number(price)!,
      durationInWeeks: duration!,
      teleconsultationMinutes: teleconsultationMinutes!,
      onlineHours: hours,
    };

    return compareObjectsChanges(data, newData);
  }, [
    watch('clinicId'),
    watch('title'),
    watch('price'),
    watch('durationInWeeks'),
    watch('durationInMonth'),
    watch('teleconsultationMinutes'),
    watch('hours'),
  ]);

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <Title>
          {t('professional_profile.subscriptions.modal_title')}
          <StyledCloseIcon onClick={handleCloseModal} />
        </Title>
        <FormsWrapper>
          <SubscriptionInfo>
            <Input
              label={t('professional_profile.subscriptions.title') || ''}
              id={'title'}
              type="text"
              register={register}
              errorMessage={errors?.title?.message}
              placeholder={
                t('professional_profile.subscriptions.title_label') || ''
              }
            />
            {!isPrivateDoctor && (
              <Dropdown
                id={'clinicId'}
                label={t('clinic') || ''}
                options={clinicsOptions!}
                placeholder={t('all_clinics') || ''}
                value={watch('clinicId') || ''}
                onChange={(value) =>
                  !Array.isArray(value) &&
                  handleSelectClinic(String(value.value))
                }
                errorMessage={errors.clinicId?.message}
              />
            )}
            <Row>
              <Input
                id={'price'}
                type={'number'}
                label={`${t('price') || ''}, €`}
                min={'0.00'}
                step={'0.01'}
                value={watch('price')}
                onChange={(e) => handleChangePrice(e.target.value)}
                errorMessage={errors?.price?.message}
              />
              <Input
                id={'teleconsultationMinutes'}
                type={'number'}
                label={`${t(
                  'professional_profile.subscriptions.teleconsultation',
                )!}, ${t('mins')!}`}
                placeholder={t('appointment_types.duration')!}
                min={'0'}
                step={'1'}
                value={String(watch('teleconsultationMinutes'))}
                onChange={(e) => {
                  setValue('teleconsultationMinutes', Number(e.target.value));
                }}
                errorMessage={errors?.teleconsultationMinutes?.message}
              />
            </Row>
            <Row>
              <Dropdown
                id={'durationInWeeks'}
                value={String(weekDuration?.value)}
                label={`${t(
                  'professional_profile.subscriptions.week_duration',
                )!}, ${t('professional_profile.subscriptions.weeks')}`}
                placeholder={t('appointment_types.duration')! || ''}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleDurationInWeeksChanged(value);
                  }
                }}
                options={getWeekDurationOptions}
                errorMessage={errors?.durationInWeeks?.message}
              />
              <Dropdown
                id={'durationInMonth'}
                value={String(monthDuration?.value)}
                label={`${t(
                  'professional_profile.subscriptions.month_duration',
                )!}, ${t('professional_profile.subscriptions.months')!}`}
                placeholder={t('appointment_types.duration')!}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleDurationInMonthChanged(value);
                  }
                }}
                options={getMonthDurationOptions}
                errorMessage={errors?.durationInMonth?.message}
              />
            </Row>
          </SubscriptionInfo>
          <OnlineHours
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            enabledWorkingHours={data?.onlineHours!}
          />
        </FormsWrapper>

        <ButtonWrapper>
          <SecondaryButton onClick={handleCloseModal}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!!Object.keys(errors).length || !hasChanges}
          >
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddSubscriptionModal;
